.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* set a high z-index to ensure it appears on top of other elements */
  }
  
  .modal-content {
    background-color: #fff;
    width: 50%;
    max-width: 90%; /*optional: set a max width to the modal */
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    margin: auto;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center; /* center horizontally */
    margin-bottom: 10px;
    position: relative; /* add position relative to create a new positioning context for the Close button */
  }
  
  .modal-header h2 {
    margin: 0;  
    text-align: center; /* center text within h2 element */
  }
  
  button.modal-close {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .modal-close {
    background-color: transparent;
    color: #a9a9a9;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .modal-close:hover {
    color: #272727;
  }
  
  .modal-body {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .modal-footer {
    display: flex;
    justify-content: center;
  }
  
  .modal-submit {
    background-color: #e87d39;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-submit:hover {
    background-color: #cc6d00;
  }
  