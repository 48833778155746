.no_group_text {
    text-align: center;
}
.groupList:hover{
  background-color: rgba(37, 37, 37, 0.5);
}

li:last-child{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
li:first-child{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
/*pop up window css*/

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 1; /* Sit on top */
    padding-top: 60px; /* Location of the box */
    padding-bottom: 200px;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 80%; /* Full height */
    overflow-y: scroll; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    /* background-color: #f4f3f3; */
    margin: auto;
    /* padding: 20px; */
    /* border: 1px solid #888; */
    /* border-radius: 5px; */
    width: 90%;
  }
  
  /* The Close Button */
  .close {
    z-index: 11;
    margin: 10px;
    padding-right: 15px;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
