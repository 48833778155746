body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.grecaptcha-badge {
    visibility: hidden;
}

.fill-window {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}




@font-face {
    font-family: "Futura";
    src: local("Futura"), url(./Fonts/Futura/futur.ttf) format("ttf");
}
@font-face {font-family: "Book Antiqua"; src: url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.eot"); src: url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.woff") format("woff"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/127984ac535ca158ad9724f752ade6a6.svg#Book Antiqua") format("svg"); }
